import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureConstants {
  static readonly GenesysIntegration = 'MarketPlaceIntegrationGroup.GenesysIntegration';
  static readonly GenesysIntegrationOutOfMarketplace = 'MarketPlaceIntegrationGroup.GenesysIntegrationOutOfMarketplace';
  static readonly AmazonIntegration = 'MarketPlaceIntegrationGroup.AmazonIntegration';
  static readonly AmazonIntegrationOutOfMarketplace = 'MarketPlaceIntegrationGroup.AmazonIntegrationOutOfMarketplace';
  static readonly Five9Integration = 'MarketPlaceIntegrationGroup.Five9Integration';
  static readonly TwilioIntegration = 'MarketPlaceIntegrationGroup.TwilioIntegration';
  static readonly NiceIntegration = 'MarketPlaceIntegrationGroup.NiceIntegration';
}
