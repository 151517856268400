<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="organizationId">
    <label for="organizationId">{{ 'Settings::Five9IntegrationOrganizationId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="organizationId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="tenantName">
    <label for="tenantName">{{ 'Settings::Five9IntegrationTenantName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tenantName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::Five9IntegrationClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::Five9IntegrationClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientId">
    <label for="caClientId">{{ 'Settings::Five9IntegrationCAClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caClientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientSecret">
    <label for="caClientSecret">{{ 'Settings::Five9IntegrationCAClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caClientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caSkillIdFor">
    <label for="caSkillIdFor">{{ 'Settings::Five9IntegrationCASkillIdFor' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caSkillIdFor"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
