import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'cr-genesys-integration-settings',
  templateUrl: './genesys-integration-settings.component.html',
  styleUrls: ['./genesys-integration-settings.component.scss'],
})
export class GenesysIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly audioHookCredentialConstantKey = "Sestek-Api-Key";
  private readonly prefix = 'GenesysIntegration';
  private readonly clientId = `${this.prefix}.ClientId`;
  private readonly clientSecret = `${this.prefix}.ClientSecret`;
  private readonly region = `${this.prefix}.Region`;
  private readonly organizationId = `${this.prefix}.OrganizationId`;
  private readonly caGroupIdFor = `${this.prefix}.CAGroupIdFor`;
  private readonly audioHookCredentialKey = `${this.prefix}.AudioHookCredentialKey`;
  private readonly audioHookCredentialSecret = `${this.prefix}.AudioHookCredentialSecret`;
  private readonly useLanguageDictionary = `${this.prefix}.UseLanguageDictionary`;
  private readonly caClientId = `${this.prefix}.CAClientId`;
  private readonly caClientSecret = `${this.prefix}.CAClientSecret`;
  private readonly languageDictionary = `${this.prefix}.LanguageDictionary`;

  useLanguageDictionaryCb = false;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.useLanguageDictionaryCb = config.getSetting(this.useLanguageDictionary) == 'true';
    this.form = this.fb.group({
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      region: this.fb.group({
        name: this.region,
        value: [
          this.config.getSetting(this.region),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      organizationId: this.fb.group({
        name: this.organizationId,
        value: [
          this.config.getSetting(this.organizationId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caGroupIdFor: this.fb.group({
        name: this.caGroupIdFor,
        value: [
          this.config.getSetting(this.caGroupIdFor),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientId: this.fb.group({
        name: this.caClientId,
        value: [
          this.config.getSetting(this.caClientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientSecret: this.fb.group({
        name: this.caClientSecret,
        value: [
          this.config.getSetting(this.caClientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      audioHookCredentialKey: this.fb.group({
        name: this.audioHookCredentialKey,
        value: [
          this.audioHookCredentialConstantKey
        ],
      }),
      audioHookCredentialSecret: this.fb.group({
        name: this.audioHookCredentialSecret,
        value: [
          this.config.getSetting(this.audioHookCredentialSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      useLanguageDictionary: this.fb.group({
        name: this.useLanguageDictionary,
        value: [this.config.getSetting(this.useLanguageDictionary)],
      }),
      languageDictionary: this.fb.group({
        name: this.languageDictionary,
        value: [this.config.getSetting(this.languageDictionary)],
      }),
    });
  }

  ngOnInit(): void {}

  generateRandomBase64String(){
    const lengthOfCode = 36;
    const possible = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.form.get("audioHookCredentialSecret.value").setValue(btoa(text));
  }

  onUseLanguageDictionaryChange(value: boolean) {
    console.log('onUseLanguageDictionaryChange triggered');
    if (!value) {
      this.form.controls.languageDictionary.clearValidators();
      console.log('languageDictionary clearValidators');
    }
    this.form.controls.languageDictionary.updateValueAndValidity();
  }
  
  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('clientId').get('name').value,
      settingValue: this.form.get('clientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('clientSecret').get('name').value,
      settingValue: this.form.get('clientSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caClientId').get('name').value,
      settingValue: this.form.get('caClientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caClientSecret').get('name').value,
      settingValue: this.form.get('caClientSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('region').get('name').value,
      settingValue: this.form.get('region').get('value').value,
    });

    settings.push({
      settingName: this.form.get('organizationId').get('name').value,
      settingValue: this.form.get('organizationId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caGroupIdFor').get('name').value,
      settingValue: this.form.get('caGroupIdFor').get('value').value,
    });

    settings.push({
      settingName: this.form.get('audioHookCredentialKey').get('name').value,
      settingValue: this.form.get('audioHookCredentialKey').get('value').value,
    });

    settings.push({
      settingName: this.form.get('audioHookCredentialSecret').get('name').value,
      settingValue: this.form.get('audioHookCredentialSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useLanguageDictionary').get('name').value,
      settingValue: this.useLanguageDictionaryCb.toString(),
    });

    settings.push({
      settingName: this.form.get('languageDictionary').get('name').value,
      settingValue: this.form.get('languageDictionary').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
