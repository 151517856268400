<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::NiceIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::NiceIntegrationClientSecret' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="accessKey">
    <label for="accessKey">{{ 'Settings::NiceIntegrationAccessKey' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="accessKey" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="accessSecret">
    <label for="accessSecret">{{ 'Settings::NiceIntegrationAccessSecret' | abpLocalization}}</label>
    <input type="text" class="form-control form-control-sm" id="accessSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caGroupIdFor">
    <label for="caGroupIdFor">{{ 'Settings::NiceIntegrationCAGroupIdFor' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caGroupIdFor" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientId">
    <label for="caClientId">{{ 'Settings::NiceIntegrationCAClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientSecret">
    <label for="caClientSecret">{{ 'Settings::NiceIntegrationCAClientSecret' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="organizationId">
    <label for="organizationId">{{ 'Settings::NiceIntegrationOrganizationId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="organizationId" formControlName="value" />
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>