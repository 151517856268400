import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'cr-nice-integration-settings',
  templateUrl: './nice-integration-settings.component.html',
  styleUrls: ['./nice-integration-settings.component.scss'],
})
export class NiceIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'NiceIntegration';
  private readonly clientId = `${this.prefix}.ClientId`;
  private readonly clientSecret = `${this.prefix}.ClientSecret`;
  private readonly caGroupIdFor = `${this.prefix}.CAGroupIdFor`;
  private readonly accessKey = `${this.prefix}.AccessKey`;
  private readonly accessSecret = `${this.prefix}.AccessSecret`;
  private readonly caClientId = `${this.prefix}.CAClientId`;
  private readonly caClientSecret = `${this.prefix}.CAClientSecret`;
  private readonly organizationId = `${this.prefix}.OrganizationId`;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caGroupIdFor: this.fb.group({
        name: this.caGroupIdFor,
        value: [
          this.config.getSetting(this.caGroupIdFor),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientId: this.fb.group({
        name: this.caClientId,
        value: [
          this.config.getSetting(this.caClientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientSecret: this.fb.group({
        name: this.caClientSecret,
        value: [
          this.config.getSetting(this.caClientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      accessKey: this.fb.group({
        name: this.accessKey,
        value: [
          this.accessKey
        ],
      }),
      accessSecret: this.fb.group({
        name: this.accessSecret,
        value: [
          this.config.getSetting(this.accessSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      organizationId: this.fb.group({
        name: this.organizationId,
        value: [
          this.config.getSetting(this.organizationId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void { }

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('clientId').get('name').value,
      settingValue: this.form.get('clientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('clientSecret').get('name').value,
      settingValue: this.form.get('clientSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caClientId').get('name').value,
      settingValue: this.form.get('caClientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caClientSecret').get('name').value,
      settingValue: this.form.get('caClientSecret').get('value').value,
    });
    settings.push({
      settingName: this.form.get('caGroupIdFor').get('name').value,
      settingValue: this.form.get('caGroupIdFor').get('value').value,
    });

    settings.push({
      settingName: this.form.get('accessKey').get('name').value,
      settingValue: this.form.get('accessKey').get('value').value,
    });

    settings.push({
      settingName: this.form.get('accessSecret').get('name').value,
      settingValue: this.form.get('accessSecret').get('value').value,
    });

    settings.push({
      settingName: this.form.get('organizationId').get('name').value,
      settingValue: this.form.get('organizationId').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
